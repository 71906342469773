<template>
  <div>
    <v-container>
      <div class="mt-6 mb-2">
        <div class="text-h5 text-center">退出登录成功</div>
        <div class="text--secondary text-center">您可以关闭此窗口了</div>
      </div>
      <v-row justify="center" class="mt-4">
        <v-btn to="/login" class="mx-auto" depressed>重新登录</v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "logout",
  mounted: function () {
    this.axios({
      method: "get",
      url: "/api/user/logout",
    })
      .then(() => {
        this.cookie.remove("token");
        this.$store.dispatch("userStatus", false);
        localStorage.removeItem("Flag");
        return;
      })
      .catch((err) => {
        console.log(err);
        this.error("发生错误:" + err);
      });
  }
};
</script>

<style></style>
